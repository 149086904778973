import React from 'react';
import { BrowserRouter as Router,Routes, Route} from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import ConsoleLayout from './components/consolse';
import WebsiteLayout from './components/website';

import elements from './routes';


const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        {elements.website.map((route, index) => (
          <Route
            key={index} // 确保每个Route都有唯一的key
            path={route.path}
            element={
              <PrivateRoute>
                <WebsiteLayout>
                  {route.element}
                </WebsiteLayout>
              </PrivateRoute>
            }
          />
        ))}
        {elements.console.map((route, index) => (
          <Route
            key={index} // 确保每个Route都有唯一的key
            path={route.path}
            element={
              <PrivateRoute>
                <ConsoleLayout>
                  {route.element}
                </ConsoleLayout>
              </PrivateRoute>
            }
          />
        ))}
      </Routes>
    </Router>
  );
}

export default App;