import React from 'react';
import {RouteObject } from 'react-router-dom';


const HomePage = React.lazy(() => import('./pages/console/home'));
const IamPage = React.lazy(() => import('./pages/console/iam'));
const LoginPage = React.lazy(() => import('./pages/website/login'));

const routes: {[key: string]:RouteObject[]} = {
    console :[
        { path: '/', element: <HomePage /> },
        { path: '/iam', element: <IamPage /> },
    ],
    website :[
        { path: '/login', element: <LoginPage /> },
    ],
};

export default routes;