import { Suspense } from 'react';
import { BellOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import './index.less'


const { Header, Content, Footer } = Layout;

function WebsiteLayout({ children }:any) {
    return (
        <Layout style={{ height: '100%' }}>
            <Header className="header">
                <div className="logo" >PingxingXYZ</div>
                <div className="func" >
          
                    <div className="itme" style={{ width: '3%' }}>
                        <BellOutlined />
                    </div>
                    <div className="itme" style={{ width: '3%' }}>
                        <QuestionCircleOutlined />
                    </div>
                    <div className="itme" >bufanqi</div>
                </div>
            </Header>
            <Content className="content" style={{ padding: '0 1%'}}>
                <Layout className="site-layout-background">
                    <Suspense fallback={<div>Loading...</div>}>
                        {children}
                    </Suspense>
                </Layout>
            </Content>
            <Footer className="footer" >
                <div>
                    官网首页 | 关于我们 | 案例展示 | 联系我们
                </div>
                <div>
                   <div>Copyright © 2024 pingxing.xyz 版权所有</div>
                   <div>粤ICP备2024288017号</div>
                </div>
            </Footer>
        </Layout>
    );
}
export default WebsiteLayout;