import  { Suspense } from 'react';
import { BellOutlined,QuestionCircleOutlined } from '@ant-design/icons';
import { Breadcrumb, Layout,Input } from 'antd';

import './index.less';

const { Header, Content, Footer } = Layout;

const { Search } = Input;

const onSearch = (value: string) => console.log(value);

function ConsoleLayout({ children }:any) {
  return (
   <Layout style={{height: '100%'}}>
      <Header className="header">
        <div className="logo" >PingxingXYZ</div>
        <div className="func" >
          <Search placeholder="input search text" onSearch={onSearch} style={{ width: 200 }} />
          <div className="itme" >更多</div>
          <div className="itme" style={{width: '3%'}}>  
            <BellOutlined />
          </div>
          <div className="itme"  style={{width: '3%'}}>  
            <QuestionCircleOutlined />
          </div>
          <div className="itme" >bufanqi</div>
        </div>
      </Header>
      <Content className="content" style={{ padding: '0 1%' }}>
        <Breadcrumb style={{ padding: '1% 0  0 0' }}>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
          <Breadcrumb.Item>App</Breadcrumb.Item>
        </Breadcrumb>
        <Layout className="site-layout-background">
          <Suspense fallback={<div>Loading...</div>}>
            {children}
          </Suspense>
        </Layout>
      </Content>
      <Footer className="footer" >Copyright © 2024 pingxing.xyz 版权所有</Footer>
    </Layout>
  );
}

export default ConsoleLayout;