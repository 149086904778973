import { Navigate} from 'react-router-dom';
import WebsiteLayout from './website';

function PrivateRoute({ children }:any) {
    const isAuthenticated = false; 
    // 这里应该替换为实际的认证逻辑
    if(children.type !== WebsiteLayout){
      return isAuthenticated ? children : <Navigate to="/login" />;
    }
    return children;
  }

export default PrivateRoute;